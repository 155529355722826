import './styles.css'

const RequestFormFileButton = ({ handleClick, icon, text }) => {
  return (
    <button type='button' onClick={handleClick} className='RequestFormFileButton'>
      <img src={icon} alt={text} />
      {text}
    </button>
  )
}

export default RequestFormFileButton