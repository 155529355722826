const validarFormulario = (user, password) => {
  if (!user || !password) {
    return false;
  }
  return true;
}

const getInitials = (name) => {
  if (name.indexOf(' ') === -1) {
    return name.charAt(0).toUpperCase()
  }

  const names = name.split(' ')
  const initials = names[0].charAt(0) + names[1].charAt(0)
  return initials.toUpperCase()
}

export {
  validarFormulario,
  getInitials,
}