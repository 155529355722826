import { useState } from 'react'
// import axios from "axios"
import { useNavigate, useParams} from 'react-router-dom'

import RequestStatusCard from 'components/RequestStatusCard'

import './styles.css'

// const REQUEST_URL = `${process.env.REACT_APP_API_URL}/product/request`;

const status = {
  _pending: {
    label: 'Pendiente',
    description: 'Su solicitud ha sido recibida',
    date: null,
    display: false
  },
  _in_preparation: {
    label: 'En preparación',
    description: 'Ya estamos armando su pedido',
    date: null,
    display: false
  },
  _in_distribution: {
    label: 'En distribución',
    description: 'Su pedido está en viaje',
    date: null,
    display: false
  },
  _delivered: {
    label: 'Entregado',
    description: 'Ya hemos entregado su pedido',
    date: null,
    display: false
  }
}

const pad =  (str, max) => {
  str = str.toString();
  return str.length < max ? pad("0" + str, max) : str;
}

const RequestStatusPage = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();

  const [pending, setPending] = useState({...status._pending, ...requestStatus.pending})
  const [inPreparation, setInPreparation] = useState({...status._in_preparation, ...requestStatus.in_preparation})
  const [inDistribution, setInDistribution] = useState({...status._in_distribution, ...requestStatus.in_distribution})
  const [delivered, setDelivered] = useState({...status._delivered, ...requestStatus.delivered})

  const handleGoBack = (path) => {
    navigate(path)
  }

  return (
    <>
      <div className="RequestStatusPage body-home-patient">
        <div className='RequestStatusPage-go-back' onClick={() => handleGoBack(`/solicitudes/${requestId}`)}>
          <i className='fas fa-chevron-left' />
        </div>

        <header className="RequestStatusPage-header header-int">
          <img src="/logo-godbt.png" height="74" width="115" alt='Logo' />

          <div>
            <img src='/icons/location-info.png' alt='Estado' />
            <p className='RequestStatusPage-header--title'>Estado de pedido</p>
            <p className='RequestStatusPage-header--request-id'>Num. {pad(requestId, 8)}</p>
          </div>
        </header>

        <main className="RequestStatusPage-content row">
          <div className="status-timeline">
            <div className="status-outer">
              <RequestStatusCard status={pending} number={1} />
              <RequestStatusCard status={inPreparation} number={2} />
              <RequestStatusCard status={inDistribution} number={3} />
              <RequestStatusCard status={delivered} number={4} />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

const requestStatus = {
  pending: {
    display: true,
    date: "2021-05-10T12:00:00.000Z",
  },
  in_preparation: {
    display: true,
    date: "2021-05-10T12:00:00.000Z",
  },
  in_distribution: {
    display: false,
    date: null,
  },
  delivered: {
    display: false,
    date: null,
  }
}

export default RequestStatusPage;