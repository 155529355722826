import { useNavigate } from 'react-router-dom'

import './styles.css'

const GoBackHeader = ({ goBackTo, title }) => {
  const navigate = useNavigate()

  const handleGoBack = (path) => {
    navigate(path)
  }

  return (
    <header className='GoBackHeader'>
      <div onClick={() => handleGoBack(goBackTo)} className='GoBackHeader-back'>
        <i className='fas fa-chevron-left' />
      </div>

      <h1 className='GoBackHeader-title'>{title}</h1>
      <div className='GoBackHeader-div-helper' />
    </header>
  )
}

export default GoBackHeader