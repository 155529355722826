// import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from 'axios';
import useAuth from "../hooks/useAuth";

const AUTH_URL =  `${process.env.REACT_APP_API_URL}/auth/login`;

const RequireAuth = ({ allowedRoles }) => {
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")).token : null;
  const configGetAuth = {
    method: 'get',
    url: AUTH_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  }

  const verify = async()=>{
    try {
      await axios(configGetAuth)
    }
    catch (err) {
      setAuth({});
      window.localStorage.removeItem('auth')
      navigate('/login');
    }
  }

  verify();

  return (
    auth?.user
      ? <Outlet/>
      : <Navigate to="/login" state={{ from: location }} replace />

    // auth?.roles?.find(role => allowedRoles?.includes(role))
    //   ? <Outlet />
    //   : auth?.user
    //     ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //     : <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default RequireAuth;