import { useNavigate, useParams } from 'react-router-dom'

import './styles.css'

const RequestCard = ({ data, divider, children, detail = false }) => {
  const { requestId } = useParams()
  const navigate = useNavigate()

  let cardClassNames = 'RequestCard'
  if (!children) cardClassNames += ' no-header'

  let childClassNames = 'RequestCard-content--child'
  if (divider) childClassNames += ' divider'
  if (!children) childClassNames += ' no-header'

  const goToRequestDetailStatus = () => {
    navigate(`/estado-solicitud/${requestId}`)
  }

  return (
    <div className={cardClassNames}>
      {children}

      <div className='RequestCard-content'>
        {Object.entries(data).map(([key, value]) => (
          <div key={key} className={childClassNames}>
            <p>{key}</p>
            <p>{value}</p>
          </div>
        ))}
        {detail &&
          <div className='RequestCard-detail'>
            <button onClick={goToRequestDetailStatus}>
              Ver pedido
            </button>
            <img src='/icons/ver-pedido.png' alt='Ver pedido' />
          </div>
        }
      </div>
    </div>
  )
}

export default RequestCard
