import './styles.css'

const FormGroup = ({ label, htmlFor, icon, children }) => {
  return (
    <div className='FormGroup'>
      <div className='FormGroup-label'>
        <img src={icon} alt={`${icon}-icon`} />
        <label htmlFor={htmlFor}>{label}</label>
      </div>
      {children}
    </div>
  )
}

export default FormGroup