import './styles.css'

const RequestSection = ({ title, children }) => {
  return (
    <div className='RequestSection'>
      <p className='RequestSection-title'>{title}</p>
      {children}
    </div>
  )
}

export default RequestSection