import './styles.css'

const PatientAction = ({ handleClick, icon, action, isLast = false }) => {
  return (
    <button onClick={handleClick} className={`PatientAction ${!isLast ? 'not-last' : ''}`}>
      <div>
        <img className='PatientAction-icon' src={icon} alt={action} />
        <p className='PatientAction-name'>{action}</p>
      </div>
      <i className='fas fa-chevron-right' />
      {/* <img className='PatientAction-chevron' src='lock.png' alt='chevron' /> */}
    </button>
  )
}

export default PatientAction