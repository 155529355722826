import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import useAuth from 'hooks/useAuth';
import useGoToLogin from 'hooks/useGoToLogin'
import useNewRequest from 'hooks/useNewRequest'
import useGetRequestTypes from 'hooks/useGetRequestTypes'
// import useUploadRequestImages from 'hooks/useUploadRequestImages'

import GoBackHeader from 'components/GoBackHeader'
import RequestFormGroup from 'components/RequestForm/Group'
import RequestFormLabel from 'components/RequestForm/Label'
import RequestFormFileButton from 'components/RequestForm/FileButton'
import FormButton from 'components/Form/Button'

import './styles.css'

const MySwal = withReactContent(Swal)

const NewRequestPage = () => {
  const { auth } = useAuth()
  const { types, typesError } = useGetRequestTypes()
  const [newRequest, loading, error] = useNewRequest()
  // const [uploadImages, uploadLoading, uploadError] = useUploadRequestImages()

  const [fileList, setFileList] = useState(null)
  const files = fileList ? [...fileList] : []
  const navigate = useNavigate()

  const date = new Date().toISOString().split('T')[0]
  let typeRef = useRef(null)
  let textRef = useRef(null)
  let fileRef = useRef(null)

  useGoToLogin()

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return
    }

    setFileList(e.target.files)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const images = new FormData()
    files.forEach((file, i) => {
      images.append('files', file.name)
    })

    newRequest({
      patient_id: auth?.user?.patient_id,
      request_type_id: parseInt(typeRef.value),
      request: textRef.value,
    }, images)
      .then(res => {
        MySwal.fire({
          title: '<p>Se ha enviado la solicitud con éxito.</p>',
          width: '600',
          icon: 'success',
          confirmButtonText: '<p>Volver</p>',
          confirmButtonColor: '#2F0287',
          backdrop: 'rgba(0, 0, 0, 0.7)'
        })
        .then(result => {
          if (result.isConfirmed) {
            navigate('/')
          }
        })
      })
      .catch(err => {
        Swal.fire({
          html: '<p>Hubo un error al enviar la solicitud.</p>',
          icon: 'error',
          iconColor:'#F08F00',
          confirmButtonText: '<p>OK</p>',
          confirmButtonColor: '#0A9E95'
        })
      })
  }

  const clickSelectFile = () => {
    fileRef.current?.click()
  }

  return (
    <div className='PatientRequest-container'>
      <GoBackHeader goBackTo='/' title='Nueva solicitud' />

      <main className='PatientRequest-main'>
        <form onSubmit={e => handleSubmit(e)} className='PatientRequest-form'>
          <div>
            <RequestFormGroup column>
              <RequestFormLabel htmlFor='request-date' text='Fecha' />
              <input
                id='request-date'
                className='PatientRequest-control'
                type='date'
                name='request-date'
                defaultValue={date}
                required
                disabled
              />
            </RequestFormGroup>

            <RequestFormGroup column>
              <RequestFormLabel htmlFor='request-type' text='Tipo de solicitud' />
              <select ref={type => typeRef = type} id='request-type' className='PatientRequest-control' name='request-type' required>
                <option value='' defaultValue>Seleccione una opción</option>
                {types?.map(type => (
                  <option key={`request-type-${type.id}`} value={type.id}>{type.type}</option>
                ))}
              </select>
            </RequestFormGroup>

            <RequestFormGroup column>
              <RequestFormLabel htmlFor='request-text' text='Solicitud' />
              <textarea ref={text => textRef = text} id='request-text' className='PatientRequest-control' rows={11} />
            </RequestFormGroup>

            <RequestFormGroup align noMargin>
              <RequestFormLabel htmlFor='request-file' text='Adjuntar documentación' noMargin />
              <RequestFormFileButton
                handleClick={clickSelectFile}
                icon='icons/upload.png'
                text='Seleccionar archivo'
              />
              <input
                ref={fileRef}
                onChange={handleFileChange}
                id='request-file'
                className='PatientRequest-control'
                type='file'
                name='request-file'
                multiple
              />
            </RequestFormGroup>
          </div>

          <FormButton text='Enviar' />
        </form>
      </main>
    </div>
  )
}

export default NewRequestPage