import { useState } from 'react'
import axios from 'axios'

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`

const useLogin = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const login = async (data) => {
    setLoading(true)

    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json'
        },
        //withCredentials: true
      })

      setLoading(false)

      return response
    } catch (error) {
      setLoading(false)
      setError(error)

      throw error
    }
  }

  return [
    login,
    loading,
    error,
  ]
}

export default useLogin
