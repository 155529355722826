import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from './useAuth'

const useGoToLogin = () => {
  const { auth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth?.token) {
      navigate('/login')
    }
  }, [auth, navigate])
}

export default useGoToLogin
