import { Navigate, Routes, Route } from 'react-router-dom'

import RequireAuth from 'components/RequireAuth'

import LoginPage from 'pages/Login'
import PatientPage from 'pages/Patient'
import NewRequestPage from 'pages/NewRequest'
import RequestsPage from 'pages/Requests'
import RequestDetailPage from 'pages/RequestDetail'
import RequestStatusPage from 'pages/RequestStatus'

function App() {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />

      <Route element={<RequireAuth />}>
        <Route path="/" element={<PatientPage />} />
        <Route path="/nueva-solicitud" element={<NewRequestPage />} />
        <Route path="/solicitudes" element={<RequestsPage />} />
        <Route path="/solicitudes/:requestId" element={<RequestDetailPage />} />
        <Route path="/estado-solicitud/:requestId" element={<RequestStatusPage />} />

        <Route path="*" element={<Navigate to={"/"} />} />
      </Route>
    </Routes>
  );
}

export default App;