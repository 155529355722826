import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import useAuth from 'hooks/useAuth'
import useGoToLogin from 'hooks/useGoToLogin'
import useGetPatientRequests from 'hooks/useGetPatientRequests'

import GoBackHeader from 'components/GoBackHeader'
import Request from 'components/Request'

import './styles.css'

const MySwal = withReactContent(Swal)

const RequestsPage = () => {
  const { auth } = useAuth()
  const [requests, setRequests] = useState(null)
  const [getPatientRequests, loading, error] = useGetPatientRequests()

  useGoToLogin()

  useEffect(() => {
    getPatientRequests(auth?.user?.patient_id)
      .then(res => {
        setRequests(res.data)
      })
      .catch(err => {
        // MySwal.fire({
        //   html: '<p>Ocurrió un error al intentar obtener las solicitudes.</p>',
        //   icon: 'error',
        //   iconColor:'#F08F00',
        //   confirmButtonText: '<p>OK</p>',
        //   confirmButtonColor: '#0A9E95'
        // })
      })
  }, [auth?.user])

  if (loading) {
    return(
      <div className='Login-container'>
        <h1>Obteniendo las solicitudes...</h1>
      </div>
    )
  }

  return (
    <div className='RequestsPage-container'>
      <GoBackHeader goBackTo='/' title='Solicitudes realizadas' />

      <div className='RequestsPage-requests'>
        {!error && requests
          ?
          requests.map((request) => (
            <Request key={`solicitud-${request.id}`} data={request} />
          ))
          : <h1 className='no-requests-found'>No ha realizado ninguna solicitud todavía.</h1>
        }
      </div>
    </div>
  )
}

export default RequestsPage