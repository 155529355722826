import { useNavigate } from 'react-router-dom'

import useAuth from 'hooks/useAuth'
import useGoToLogin from 'hooks/useGoToLogin'

import PatientImage from 'components/Patient/Image'
import PatientAction from 'components/Patient/Action'

import './styles.css'

const HEADER_IMG = 'logo-godbt.png'

const PatientPage = () => {
  const { auth } = useAuth()
  const navigate = useNavigate()

  useGoToLogin()

  const goToNewRequest = () => {
    navigate('/nueva-solicitud')
  }

  const goToRequests = () => {
    navigate('/solicitudes')
  }

  return (
    <div className='Patient-container'>
      <div>
        <header className='Patient-header'>
          <img src={HEADER_IMG} alt='godbt-logo' />
        </header>

        <main className='Patient-main'>
          <div className='Patient-data'>
            <PatientImage user={auth?.user} />
            <h1 className='Patient-data--name'>{auth?.user?.fullname}</h1>
          </div>

          <div className='Patient-actions'>
            <PatientAction
              handleClick={() => goToNewRequest()}
              icon='icons/nueva-solicitud.png'
              action='Nueva solicitud'
            />
            <PatientAction
              handleClick={() => goToRequests()}
              icon='icons/ver-solicitudes.png'
              action='Solicitudes realizadas'
            />
            <PatientAction
              handleClick={() => console.log('goToEducativeContent')}
              icon='icons/contenido-educativo.png'
              action='Contenido educativo'
              isLast
            />
          </div>
        </main>
      </div>

      <footer className='Patient-footer'>
        <div className='Patient-logout'>
          <img src='icons/logout.png' alt='Salir' />
          <p>Salir</p>
        </div>
      </footer>
    </div>
  )
}

export default PatientPage