import moment from 'moment'

const RequestStatusCard = ({ status, number, }) => {
  return (
    <div className='status-card'>
      <div className={`status-info ${status.display ? '' : 'status-light'}`}>
        <p
          className={`${status.display ? 'status-title-ok' : 'status-title-ok-light'}`}
          data-number={number}
        >
          {status?.label}
        </p>
        <p className='status-subtitle'>{status?.date ? moment(status?.date).format('DD/MM/YYYY HH:mm') : ''}</p>
        <p className='status-comment'>{status?.description}</p>
      </div>
    </div>
  )
}

export default RequestStatusCard