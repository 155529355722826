import { useNavigate } from 'react-router-dom'

import RequestCard from './Card'
import RequestStatus from './Status'

import './styles.css'

const Request = ({ data }) => {
  const navigate = useNavigate()

  const goToDetail = () => {
    navigate('/solicitudes/' + data.id)
  }

  return (
    <div className='Request-container'>
      <h1 className='Request-id'>#{data.id}</h1>

      <RequestCard data={{
        'Fecha': data.date,
        'Tipo de solicitud': data.request_type
      }} divider>
        <div className='Request-status'>
          <RequestStatus status={data.request_status} />
          <button onClick={goToDetail}>Ver</button>
        </div>
      </RequestCard>
    </div>
  )
}

export default Request
