import { useState, useEffect } from 'react'
import axios from 'axios'

const REQUESTS_TYPES_URL = `${process.env.REACT_APP_API_URL}/request-types`

const useGetRequestTypes = () => {
  const [types, setTypes] = useState(null)
  const [error, setError] = useState(null)

  const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")).token : null

  useEffect(() => {
    const getRequestTypes = async () => {
      try {
        const response = await axios.get(REQUESTS_TYPES_URL, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        })

        setTypes(response?.data)
      } catch (err) {
        setError(error)
      }
    }

    getRequestTypes()
  }, [])

  return {
    types,
    error
  }
}

export default useGetRequestTypes