import downloadIcon from 'images/download.png'
import './styles.css'

const RequestComment = ({ data }) => {
  return (
    <div className='RequestComment'>
      <div>
        <h1 className='RequestComment-title'>{data.title} - {data.date}</h1>
        <p className='RequestComment-message'>{data.message}</p>
      </div>

      <button className='RequestComment-download'>
        <img src={downloadIcon} alt='Descargar' />
      </button>
    </div>
  )
}

export default RequestComment