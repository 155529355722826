import { useState } from 'react'
import axios from 'axios'

const PATIENT_REQUESTS_URL = `${process.env.REACT_APP_API_URL}/patient`

const useGetPatientRequests = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")).token : null

  const getPatientRequests = async (patient_id) => {
    setLoading(true)

    try {
      const response = await axios.get(`${PATIENT_REQUESTS_URL}/${patient_id}/requests`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      })

      setLoading(false)

      return response
    } catch (error) {
      setLoading(false)
      setError(error)

      throw error
    }
  }

  return [
    getPatientRequests,
    loading,
    error,
  ]
}

export default useGetPatientRequests