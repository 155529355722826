import { getInitials } from 'utils'
import './styles.css'

const PatientImage = ({ user }) => {
  return (
    <div className='PatientImage'>
      {user?.image
        ? <img src='https://picsum.photos/200' alt='user' />
        : <span>{getInitials(user?.fullname)}</span>
      }
    </div>
  )
}

export default PatientImage
