import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'

import useAuth from 'hooks/useAuth'
import useLogin from 'hooks/useLogin'

import { validarFormulario } from 'utils'

import FormGroup from 'components/Form/Group'
import FormInput from 'components/Form/Input'
import FormButton from 'components/Form/Button'

import './styles.css';

const logo = 'logo-godbt.png'
const version = process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : 'v0.1.0'
const qaEnv = process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'QA' ? ' - QA' : ''

const LoginPage = () => {
  const { setAuth } = useAuth()
  const [login, loading, errorLogin] = useLogin()
  const navigate = useNavigate()

  const userRef = useRef()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const handleSubmit = () => {
    if (!validarFormulario(username, password)) {
      Swal.fire({
        html: '<p>Revisar datos</p>',
        icon: 'error',
        iconColor:'#F08F00',
        confirmButtonText: '<p>OK</p>',
        confirmButtonColor: '#0A9E95'
      })
      return;
    }

    login({ username, password })
      .then(res => {
        const token = res?.data?.token
        const user = res?.data?.user

        setAuth({ user, token })
        window.localStorage.setItem('auth', JSON.stringify({ user, token }))

        setUsername("")
        setPassword("")
        navigate("/")
      })
      .catch(err => {
        window.localStorage.removeItem('auth')

        Swal.fire({
          html: '<p>Usuario o contraseña incorrecto</p>',
          icon: 'error',
          iconColor:'#F08F00',
          confirmButtonText: '<p>OK</p>',
          confirmButtonColor: '#0A9E95'
        })
      })
  }

  if (loading) {
    return (
      <div className='Login-container'>
        <h1>Iniciando sesión...</h1>
      </div>
    )
  }

  return (
    <div className='Login-container'>
      <div className='Login-header'>
        <div className="Login-logo--container">
          <img
            className="Login-logo"
            src={logo}
            height="132"
            width="205"
            alt='logo'
          />
        </div>
      </div>

      <div className='Login-body'>
        <div className='Login-content'>
          <p className="title">Login</p>

          <div>
            <FormGroup label='Usuario' htmlFor='username' icon='icons/message.png'>
              <FormInput
                innerRef={userRef}
                handleInputChange={(e) => {setUsername(e.target.value)}}
                type='text'
                name='username'
                value={username}
                focus
                required
              />
            </FormGroup>

            <FormGroup label='Contraseña' htmlFor='password' icon='icons/lock.png'>
              <FormInput
                handleInputChange={(e)=>{setPassword(e.target.value)}}
                type='password'
                name='password'
                value={password}
                required
              />
            </FormGroup>

            <div className='Login-link'>
              <a href="#">¿Olvidaste tu contraseña?</a>
            </div>
          </div>

          <div>
            <FormButton handleClick={handleSubmit} onClick={handleSubmit} text='Ingresar' />

            <div className='Login-link--centered'>
              <a href='#'>Crear cuenta</a>
            </div>

            <p
              style={{
                fontHeight:"8px!important",
                textAlign:"center",
                marginTop:"30px",
                color:"#bcbcbc"
              }}
            >
              {version} {qaEnv}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage