import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment'

import useGoToLogin from 'hooks/useGoToLogin'
import useGetRequestById from 'hooks/useGetRequestById'

import GoBackHeader from 'components/GoBackHeader'
import RequestStatus from 'components/Request/Status'
import RequestSection from 'components/Request/Section'
import RequestCard from 'components/Request/Card'
import RequestComment from 'components/Request/Comment'

import addCommentIcon from 'images/add.png'

import './styles.css'

const MySwal = withReactContent(Swal)

const RequestDetailPage = () => {
  const { requestId } = useParams()
  const [request, setRequest] = useState(null)
  const [getRequestById, loading, error] = useGetRequestById()

  useGoToLogin()

  useEffect(() => {
    getRequestById(requestId)
      .then(res => {
        setRequest(res.data.results[0])
      })
      .catch(err => {
        MySwal.fire({
          html: '<p>Ocurrió un error al intentar obtener las solicitudes.</p>',
          icon: 'error',
          iconColor:'#F08F00',
          confirmButtonText: '<p>OK</p>',
          confirmButtonColor: '#0A9E95'
        })
      })
  }, [requestId])

  if (loading) {
    return(
      <div className='Login-container'>
        <h1>Obteniendo los datos de la solicitud...</h1>
      </div>
    )
  }

  return (
    <div className='RequestDetail-container'>
      <GoBackHeader goBackTo='/solicitudes' title='Detalle de solicitud' />

      <main className='RequestDetail-content'>
        <div className='RequestDetail-status'>
          <RequestStatus status={request?.request_status} params />
        </div>

        <RequestSection title='Datos de la solicitud'>
          <RequestCard data={{
            'Número': request?.id,
            'Fecha': moment(request?.date).format('DD-MM-YYYY'),
            'Tipo de solicitud': request?.request_type
          }} />
        </RequestSection>

        <RequestSection title='Detalle de la solicitud'>
          <RequestCard data={{
            'Medicamento': detail.detail.drug,
            'Cantidad': detail.detail.quantity,
            'Período': detail.detail.period,
            'Droguería': detail.detail.drugstore
          }} detail/>
        </RequestSection>

        <div className='RequestDetail-comments'>
          <div className='RequestDetail-comments--header'>
            <h1 className='RequestDetail-comments--header-title'>Comentarios</h1>

            <div className='RequestDetail-comments--header-btn'>
              <p>Agregar</p>
              <button>
                <img src={addCommentIcon} alt='Agregar' />
              </button>
            </div>
          </div>

          <div className='RequestDetail-comments--list'>
            {detail.comments.map(comment => (
              <RequestComment key={comment.id} data={comment} />
            ))}
          </div>
        </div>
      </main>
    </div>
  )
}

let detail = {
  detail: {
    drug: 'Xaretto, 15mg comp.x 28',
    quantity: 2,
    period: '60 días',
    drugstore: 'Drofar'
  },
  comments: [
    {
      id: 1,
      title: 'OSPJN',
      date: '04/11/2022',
      message: 'Lorem ipsum dolot sit amet, consectetur adispicing elit, sed do eiusmod tempor incididunt.'
    },
    {
      id: 2,
      title: 'OSPJN',
      date: '02/10/2022',
      message: 'Lorem ipsum dolot sit amet, consectetur adispicing elit, sed do eiusmod tempor incididunt.'
    },
    {
      id: 3,
      title: 'OSPJN',
      date: '01/10/2022',
      message: 'Lorem ipsum dolot sit amet, consectetur adispicing elit, sed do eiusmod tempor incididunt.'
    }
  ]
}

export default RequestDetailPage
