import './styles.css'

const RequestFormLabel = ({ htmlFor, text, noMargin = false }) => {
  return (
    <label className={`RequestFormLabel ${!noMargin ? 'mb-2' : ''}`} htmlFor={htmlFor}>
      {text}
    </label>
  )
}

export default RequestFormLabel
