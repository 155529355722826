import { useState } from 'react'
import axios from 'axios'

import useUploadRequestImages from 'hooks/useUploadRequestImages'

const NEW_REQUEST_URL = `${process.env.REACT_APP_API_URL}/request`

const useNewRequest = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [uploadImages] = useUploadRequestImages()

  const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")).token : null

  const newRequest = async (data, images) => {
    setLoading(true)

    try {
      const response = await axios.post(NEW_REQUEST_URL, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        //withCredentials: true
      })

      if (response.data?.id) {
        uploadImages(response.data.id, images)
          .then(res => {
            setLoading(false)
            return res
          })
          .catch(error => {
            setLoading(false)
            setError(error)
            throw error
          })
      }
    } catch (error) {
      setLoading(false)
      setError(error)

      throw error
    }
  }

  return [
    newRequest,
    loading,
    error,
  ]
}

export default useNewRequest