import { useState } from 'react'
import axios from 'axios'

const REQUEST_URL = `${process.env.REACT_APP_API_URL}/request`

const useUploadRequestImages = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")).token : null

  const uploadImages = async (request_id, images) => {
    setLoading(true)

    try {
      const response = await axios.post(`${REQUEST_URL}/${request_id}/files`, images, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        },
      })

      setLoading(false)

      return response
    } catch (error) {
      setLoading(false)
      setError(error)

      throw error
    }
  }

  return [
    uploadImages,
    loading,
    error,
  ]
}

export default useUploadRequestImages