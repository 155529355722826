import { useEffect, useState } from 'react'

import approveIcon from 'images/approve.png'
import pendingIcon from 'images/pending.png'
import rejectedIcon from 'images/rejected.png'
// import questionIcon from 'images/question.png'

import './styles.css'

const RequestStatus = ({ status, params }) => {
  const [icon, setIcon] = useState(null)
  const [file, setFile] = useState('')

  useEffect(() => {
    const statusIcon = (status) => {
      switch (status) {
        case 'Autorizado':
          setFile('icons/approve.png')
          setIcon(approveIcon)
          break
        case 'Pendiente':
          setFile('icons/pending.png')
          setIcon(pendingIcon)
          break
        case 'Rechazado':
          setFile('icons/rejected.png')
          setIcon(rejectedIcon)
          break
        default:
          setFile('')
          setIcon(null)
      }
    }

    statusIcon(status)
  }, [status])

  return (
    <div className='RequestStatus'>
      <img src={params ? icon : file} alt={status} />
      <p>{status}</p>
    </div>
  )
}

export default RequestStatus