const RequestFormGroup = ({ children, align = false, column = false, noMargin = false }) => {
  let styles = 'd-flex'
  styles += align ? ' align-items-center' : ''
  styles += column ? ' flex-column' : ''
  styles += !noMargin ? ' mb-4' : ''

  return (
    <div className={styles}>
      {children}
    </div>
  )
}

export default RequestFormGroup