import './styles.css'

const FormInput = ({ innerRef, handleInputChange, type, name, value, autoComplete = false, focus, required = false }) => {
  return (
    <input
      ref={innerRef}
      onChange={handleInputChange}
      className='FormInput'
      type={type}
      name={name}
      defaultValue={value}
      autoComplete={!autoComplete ? 'off' : 'on'}
      autoFocus={focus}
      required={required}
    />
  )
}

export default FormInput
