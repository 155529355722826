import './styles.css'

const FormButton = ({ handleClick, alternative = false, text }) => {
  const isAlternative = alternative ? 'alternative' : 'primary'

  return (
    <div className='FormButton'>
      <button type='submit' onClick={handleClick} className={`btn ${isAlternative}`}>
        {text}
      </button>
    </div>
  )
}

export default FormButton